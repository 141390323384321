import type { ColumnDef } from '@tanstack/react-table';
import type { PermissionListResponse } from 'api/permission/Permission.schemas.ts';
import type { StatControllerListPlatformResponse } from 'api/stat/Stat.schema.ts';
import { GET, POST } from 'common/helpers.ts';
import { MPOST } from 'common/helpers.ts';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import type { Permission } from 'types/Permission.ts';
import { Button } from 'ui/component/Button.tsx';
import { Spinner } from 'ui/component/Spinner.tsx';
import KPI from '#admin/component/KPI.tsx';
import { Table } from '#admin/component/Table.tsx';
import useMe from '#admin/hook/useMe.tsx';

const PlatformStats = () => {
  const me = useMe();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (me === undefined) return; // tofix: we need suspense in useMe, we have undefined until it's fetch is resolved
    if (me?.role !== 'ADMIN') navigate('/');
  }, [me, navigate]);

  const { data: stats, error: statsError } = useSWR<StatControllerListPlatformResponse>(me ? ['/stat/list-platform'] : null, GET, { refreshInterval: 60_000 });

  const uncacheMutation = useSWRMutation('/operator/uncache', MPOST);

  const permissionsSort = [
    ['user_id', 'asc'],
    ['resource_type', 'asc'],
  ];
  const permissionsSWR = useSWR<PermissionListResponse>(me ? ['/permission/list', { sort: permissionsSort }] : null, POST);

  if (statsError) return <div>Error fetching KPIs: {statsError.message}</div>;
  if (!me || !stats) return <Spinner centered={true} />;

  const { ACTIVE_USERS, TOTAL_USERS, ACTIVE_ADMINS, TOTAL_ADMINS, ACTIVE_AGENTS, TOTAL_AGENTS, ACTIVE_DRIVERS, TOTAL_DRIVERS } = stats;
  const { ACTIVE_SHIPMENTS, TOTAL_SHIPMENTS, ACTIVE_LEGS, TOTAL_LEGS, TRACKED_SHIPMENTS } = stats;
  const { AVG_TXS_4H } = stats;

  return (
    <main className="container m-auto flex w-full flex-col px-10">
      {/*<main className="m-auto grid w-full grid-cols-[repeat(auto-fit,390px)] justify-center gap-4">*/}
      <div className="container mb-4 flex flex-col gap-8">
        <div className="-z-30 absolute inset-0 bg-blue-light" />

        <div className="lg grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4">
          <h1 className="col-span-full col-start-1 mt-6">Operator actions</h1>

          <Button type="button" className="red w-max" onClick={() => uncacheMutation.trigger({})} disabled={uncacheMutation.isMutating} loading={uncacheMutation.isMutating}>
            Uncache
          </Button>
        </div>

        <div className="lg grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4">
          <h1 className="col-span-full col-start-1 mt-6">Users stats</h1>
          <p className="col-span-full col-start-1 mb-3">The user is considered active if an action was performed in the last 2d.</p>
          <KPI className="col-start-1" color={'blue'} title={'Users'} value={TOTAL_USERS} comparisonValue={`(${ACTIVE_USERS} active)`} />
          <KPI className="" color={'blue'} title={'Admins'} value={TOTAL_ADMINS} comparisonValue={`(${ACTIVE_ADMINS} active)`} />
          <KPI className="" color={'blue'} title={'Agents'} value={TOTAL_AGENTS} comparisonValue={`(${ACTIVE_AGENTS} active)`} />
          <KPI className="" color={'blue'} title={'Drivers'} value={TOTAL_DRIVERS} comparisonValue={`(${ACTIVE_DRIVERS} active)`} />
        </div>

        <div className="lg grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4">
          <h1 className="col-start-1 mt-6">Shipments stats</h1>
          <KPI
            className="col-start-1"
            color={'blue'}
            title={'Active shipments'}
            value={ACTIVE_SHIPMENTS}
            comparisonValue={`/ ${TOTAL_SHIPMENTS}`}
            description={'Count of shipments pending delivery.'}
          />

          <KPI className="" color={'blue'} title={'Active legs'} value={ACTIVE_LEGS} comparisonValue={`/ ${TOTAL_LEGS}`} description={'Count of legs pending delivery.'} />

          <KPI className="" color={'blue'} title={'Tracked shipments'} value={TRACKED_SHIPMENTS} comparisonValue={`/ ${ACTIVE_SHIPMENTS}`} />

          <div className="paper !p-6 !pb-0 col-span-2 flex h-[200px] flex-col gap-4">
            <h3 className="blue">Shipments imported in the last 7d</h3>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={stats.shipments_import_history} margin={{ left: -10, top: 10, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="timestamp" tickFormatter={(value) => dayjs(value).format('HH:mm')} />
                <YAxis dataKey="count" />
                <Bar dataKey="count" fill="#000">
                  <LabelList dataKey="count" position="top" formatter={(value) => value || ''} />
                </Bar>
                <Tooltip labelFormatter={(value) => dayjs(value).format('lll')} />
              </BarChart>
            </ResponsiveContainer>
          </div>

          <div className="paper !p-6 !pb-0 col-span-2 flex h-[200px] flex-col gap-4">
            <h3 className="blue">Shipments exported in the last 7d</h3>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={stats.shipments_export_history} margin={{ left: -10, top: 10, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="timestamp" tickFormatter={(value) => dayjs(value).format('HH:mm')} />
                <YAxis dataKey="count" />
                <Bar dataKey="count" fill="#000">
                  <LabelList dataKey="count" position="top" formatter={(value) => value || ''} />
                </Bar>
                <Tooltip labelFormatter={(value) => dayjs(value).format('lll')} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="lg grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4">
          <h1 className="col-start-1 mt-6">Blockchain stats</h1>
          <KPI className="col-start-1" color={'blue'} title={'Avg block tx'} value={AVG_TXS_4H} description={'Average transactions mined per block in the last 4h.'} />

          <div className="paper !p-6 !pb-0 col-span-full col-start-2 flex h-[200px] flex-col gap-4">
            <h3 className="blue">Transactions per minute in the last 2h</h3>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={stats.blockchain_history} margin={{ left: -10, top: 10, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="timestamp" tickFormatter={(value) => dayjs(value).format('HH:mm')} />
                <YAxis dataKey="transactionCount" />
                <Bar dataKey="transactionCount" fill="#000" />
                <Tooltip labelFormatter={(value, [data]) => dayjs(value).format('lll') + ` (block ${data?.payload?.blockNumber})`} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <h1 className="col-start-1 mt-6">Permissions</h1>
          <div className="paper w-full">
            <Table estimateSize={40} columns={PermissionColumns} swr={permissionsSWR} />
          </div>
        </div>
      </div>
    </main>
  );
};

export default PlatformStats;

const PermissionColumns: ColumnDef<Permission>[] = [
  { accessorKey: 'user_name', header: 'User', minSize: 200 },
  { accessorKey: 'action', header: 'Action', size: 100 },
  { accessorKey: 'resource_type', header: 'Resource', size: 100 },
  { accessorKey: 'resource_id', header: 'Resource ID', minSize: 250 },
  { accessorKey: 'owner_name', header: 'Owner', minSize: 200 },
];
