import type { Leg } from 'types/Leg.ts';
import type { Package } from 'types/Package.ts';
import type { Participant } from 'types/Participant.ts';

export const SHIPMENT_STATES = ['draft', 'confirmed', 'cancelled'] as const;
export type SHIPMENT_STATE = (typeof SHIPMENT_STATES)[number];

export const SHIPMENT_STATUSES = ['draft', 'pending', 'transiting', 'delivered', 'cancelled'] as const;
export type SHIPMENT_STATUS = (typeof SHIPMENT_STATUSES)[number];

export type ShipmentRow = {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  creator_id: string;
  owner_id: string;
  logistics_ex_id: string;
  logistics_imported_at: string | null;
  metrics_ex_id: string | null;
  metrics_imported_at: string | null;
  tracker_sc_synced_at: string | null;
  tracker_sc_sync_pending: boolean;
  customer_id: string;
  shipper_id: string;
  consignee_id: string;
  shipper_order_reference: string | null;
  service_type: string | null;
  is_dangerous_goods: boolean;
  is_excepted_quantities: boolean;
  is_limited_quantity: boolean;
  instructions: string | null;
  terms_url: string | null;
  metadata: string | null;
  state: SHIPMENT_STATE;
  // computed on the fly in the repository
  status: SHIPMENT_STATUS;
  collection_country: string | null; // null for draft shipments having no legs
  collection_datetime: string | null; // null for draft shipments having no legs
  collection_tz: string | null; // null for draft shipments having no legs
  delivery_country: string | null; // null for draft shipments having no legs
  delivery_datetime: string | null; // null for draft shipments having no legs
  delivery_tz: string | null; // null for draft shipments having no legs
  delayed: boolean;
};

export type ShipmentRowInsert = Partial<
  Omit<ShipmentRow, 'state' | 'status' | 'collection_country' | 'collection_datetime' | 'collection_tz' | 'delivery_country' | 'delivery_datetime' | 'delivery_tz' | 'delayed'>
> &
  NonNullable<Pick<ShipmentRow, 'creator_id' | 'owner_id' | 'logistics_ex_id' | 'customer_id' | 'shipper_id' | 'consignee_id' | 'state'>>;

export type ShipmentRowUpdate = Partial<Omit<ShipmentRow, 'id'>>;

// user joined with relations
export type Shipment = ShipmentRow & {
  customer: Participant;
  shipper: Participant;
  consignee: Participant;
  legs: Leg[];
  packages: Package[];
};
