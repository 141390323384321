import { addHexPrefix, computeHash } from 'common/helpers.ts';
import useBlockchain from 'common/hooks/useBlockchain.ts';
import React from 'react';
import useEnv from './useEnv.tsx';

export type VerificationResult = {
  filename: string;
  file: File;
  organizationName?: string;
  status: 'verified' | 'not-verified' | 'error';
  timestamp: string;
  hash?: string;
  creationTimestamp?: bigint;
};

export default function useVerifyDocuments(files: File[]) {
  const env = useEnv();
  const [results, setResults] = React.useState<VerificationResult[]>([]);
  const [isVerifying, setIsVerifying] = React.useState<boolean>(false);
  const { vault, auth } = useBlockchain(env);

  const verify = React.useCallback(async () => {
    if (!vault || !auth) return;

    setIsVerifying(true);
    setResults([]);

    for (const file of files) {
      try {
        const docArrayBuffer = await file.arrayBuffer();
        const hashString = await computeHash(docArrayBuffer);
        const hashWithPrefix = addHexPrefix(hashString);
        const timestamp = new Date().toLocaleString();
        const filename = file.name;

        const scDocumentVerification = await vault.verifyDocument(hashWithPrefix);

        if (scDocumentVerification.isValid === true) {
          const owner = await auth.getUser(scDocumentVerification.ownerId);
          if (!owner) {
            throw new Error('Owner not found');
          }
          const organization = await auth.getOrganization(owner.organisationId);
          if (!organization) {
            throw new Error('Organization not found');
          }

          setResults((current) => [
            ...current,
            {
              organizationName: organization.name,
              filename: file.name,
              status: 'verified',
              file,
              hash: hashWithPrefix,
              timestamp,
              creationTimestamp: scDocumentVerification.timestamp,
            },
          ]);
        } else {
          setResults((current) => [
            ...current,
            {
              status: 'not-verified',
              filename,
              file,
              hash: hashWithPrefix,
              timestamp,
            },
          ]);
        }
      } catch (e) {
        console.error(e);
      }
    }

    setIsVerifying(false);
  }, [auth, files, vault]);

  React.useEffect(() => {
    void verify();
  }, [verify]);

  return { results, isVerifying };
}
