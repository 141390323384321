import type { ColumnDef, SortingState } from '@tanstack/react-table';
import type { StatControllerListResponse } from 'api/stat/Stat.schema.ts';
import cx from 'clsx';
import { dateToLocalYMDHM } from 'common/date.ts';
import {
  GET,
  POST,
  emptyToUndefined,
  fromQuerystring,
  getActiveLeg,
  getAlertsToRead,
  getRuntimeTimezone,
  getStatusColor,
  stringToPosition,
  toQuerystring,
  truncate,
  uniqueBy,
} from 'common/helpers.ts';
import { capitalize, groupBy, isEqual, pullAt } from 'es-toolkit';
import type { LatLngExpression } from 'leaflet';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import type { Leg } from 'types/Leg.ts';
import type { Package } from 'types/Package.ts';
import type { ROLE } from 'types/Role.ts';
import { type SHIPMENT_STATUS, SHIPMENT_STATUSES, type Shipment } from 'types/Shipment.ts';
import { Button } from 'ui/component/Button.tsx';
import { CopyableText } from 'ui/component/CopyableText.tsx';
import { IconBadge } from 'ui/component/IconBadge.tsx';
import { IClock, IFilters, IInfo, IMap, ISearch, ITemperature, ITruck, IWarning } from 'ui/component/Icons.tsx';
import { Spinner } from 'ui/component/Spinner.tsx';
import { Tag } from 'ui/component/Tag.tsx';
import { Tooltip } from 'ui/component/Tooltip.tsx';
import { Input } from 'ui/control/Input.tsx';
import { BooleanOptions, SelectMulti } from 'ui/control/SelectMulti.tsx';
import { Datetime } from '#admin/component/Datetime.tsx';
import { DriverAssignmentDialog } from '#admin/component/DriverAssignmentDialog.tsx';
import type { Marker } from '#admin/component/Map.tsx';
import { ShipmentListItemMenu } from '#admin/component/ShipmentListItemMenu.tsx';
import { Table, getSWRKeyForPage } from '#admin/component/Table.tsx';
import { MarkerTruck } from '#admin/component/markers.tsx';
import useMe from '#admin/hook/useMe.tsx';

const MapComponent = React.lazy(() => import('../component/Map.tsx'));

const getColumns = (
  role: ROLE | undefined,
  shipments: Shipment[] | undefined,
  showMap: boolean,
  buildOnClickDriverAssignHandler: (shipment: Shipment) => () => void,
): ColumnDef<Shipment>[] => {
  if (!role || !shipments) return [];

  const defaultColumns: ColumnDef<Shipment>[] = [
    {
      accessorKey: 'logistics_ex_id',
      header: 'Order n°',
      size: 160,
      cell: (info) => {
        const value = info.getValue<string>();
        if (!value) return '-';
        if (info.row.original.state === 'draft') return value;

        const is_tracked = info.row.original.packages.some((pkg: Package) => pkg.tracker);
        const is_delayed = info.row.original.delayed;

        const render = (showTooltip: boolean) => (
          <Link className="anchor anchor-novisit flex flex-row items-center gap-1" to={`/shipment/${info.row.original.id}`} data-table-no-row-events>
            {showTooltip && (
              <Tooltip
                text={
                  'There is more than 1 shipment to be picked up at the same time and postal code. For optimization purposes, please consider assigning the same driver to these shipments.'
                }
              >
                <IInfo className="fill-grey-dark" />
              </Tooltip>
            )}
            {value}
            <div className="flex flex-row gap-0">
              {is_tracked && <ITemperature className="fill-grey-dark" title="Tracker associated to at least 1 package" />}
              {is_delayed && <IClock className="fill-red" title="Delayed shipment" />}
            </div>
          </Link>
        );

        const currentLeg = getActiveLeg(info.row.original);
        if (!currentLeg || role !== 'AGENT' || !currentLeg.origin_postcode || !currentLeg.destination_postcode) return render(false);

        const allCurrentLegs = shipments
          .filter((shipment) => shipment.legs)
          .filter((shipment) => ['pending', 'transiting'].includes(shipment.status))
          .map((shipment) => getActiveLeg(shipment));

        if (allCurrentLegs.length <= 1) return render(false);

        const getGroupingKey = (leg: Leg) => {
          if (leg.origin_postcode && leg.origin_expected_datetime && !leg.origin_actual_datetime) {
            return `origin_${leg.origin_postcode}_${leg.origin_expected_datetime}`;
          }
          // Suggestion: We could include more complex cases too, example:
          //    Leg 1 -> pickup at lab X @ 10:00 / delivery at hospital A @ 13:00
          //    Leg 2 -> pickup at hospital A @ 13:00 / delivery at hospital B @ 15:00
          // they could both be assigned to the same driver
          return 'no_group';
        };

        const groupedItems = groupBy(allCurrentLegs, getGroupingKey);

        const showTooltip = groupedItems[getGroupingKey(currentLeg)]?.length > 1;

        return render(!!showTooltip);
      },
    },
    {
      accessorKey: 'created_at',
      header: 'Created At',
      size: 225,
      cell: (info) => {
        const { created_at } = info.row.original;
        return dateToLocalYMDHM(created_at);
      },
    },
    {
      accessorKey: 'collection_country',
      header: 'From',
      size: 125,
    },
    {
      accessorKey: 'collection_datetime',
      header: 'Collection At',
      size: 300,
      cell: (info) => {
        const { collection_datetime, collection_tz } = info.row.original;

        return (
          <Datetime timezone={collection_tz || getRuntimeTimezone()} truncateRegion={true}>
            {collection_datetime}
          </Datetime>
        );
      },
    },
    {
      accessorKey: 'delivery_country',
      header: 'To',
      size: 125,
    },
    {
      accessorKey: 'delivery_datetime',
      header: 'Delivery At',
      size: 300,
      cell: (info) => {
        const { delivery_datetime, delivery_tz } = info.row.original;
        return (
          <Datetime timezone={delivery_tz || getRuntimeTimezone()} truncateRegion={true}>
            {delivery_datetime}
          </Datetime>
        );
      },
    },
    {
      id: 'alerts',
      header: 'Alerts',
      size: 150,
      cell: (info) => {
        // tofix: urgent! alerts on shipments is not correctly handled, we are pushing inside the package.alerts thus duplicating them for every package
        const alertsWithDupes = info.row.original.packages.flatMap((pkg: Package) => pkg.alerts);
        const alerts = alertsWithDupes.filter(uniqueBy('id'));
        const alertsToRead = getAlertsToRead(alerts);

        return (
          <Link to={`/alerts?search=${info.row.original.id}`} className="contents">
            <Tag className={cx('tag-alerts cursor-pointer', alertsToRead.length > 0 ? 'red' : 'grey')}>
              <React.Fragment>
                {alertsToRead.length} of {alerts.length}
              </React.Fragment>
            </Tag>
          </Link>
        );
      },
    },
    {
      accessorKey: 'status',
      header: 'Status',
      size: 150,
      cell: (info) => {
        const value = info.getValue<SHIPMENT_STATUS>();
        const renderTag = (status: SHIPMENT_STATUS) => <Tag className={cx('!p-2', getStatusColor(status))}>{capitalize(status)}</Tag>;
        if (!value) return '-';

        return renderTag(info.row.original.status);
      },
    },
    {
      id: 'actions',
      header: '',
      size: 40,
      meta: { className: '!p-0' },
      cell: (info) => {
        const shipment = info.row.original;
        if (shipment.state === 'draft' || shipment.state === 'cancelled') return null;
        return <ShipmentListItemMenu onClickDriverAssign={buildOnClickDriverAssignHandler(info.row.original)} shipment={info.row.original} />;
      },
    },
  ];

  // if map is open we remove the datetime cols are there's not enough space
  if (showMap) {
    const keysToRemove = ['collection_country', 'delivery_country', 'collection_datetime', 'delivery_datetime'];
    const indexes = keysToRemove.map((key) => defaultColumns.findIndex((col) => 'accessorKey' in col && col.accessorKey === key)).filter((index) => index !== -1);
    pullAt(defaultColumns, indexes);
  }

  // if role is agent, we have different/some columns to add relative to the leg this agent will be assigned to
  const agentColumns: ColumnDef<Shipment>[] = [
    {
      header: 'Pickup postal code',
      accessorFn: (row) => row.legs?.[0].origin_postcode ?? null,
      cell: (info) => {
        const value = info.getValue<string>();
        return value || '-';
      },
    },
    {
      header: 'Delivery postal code',
      size: 175,
      accessorFn: (row) => row.legs?.[0].destination_postcode ?? null,
      cell: (info) => {
        const value = info.getValue<string>();
        return value || '-';
      },
    },
    {
      header: 'Driver',
      size: 300,
      accessorFn: (row) => row.legs?.[0].driver_id ?? null,
      cell: (info) => {
        const activeLeg = getActiveLeg(info.row.original);
        const driver = activeLeg.driver;
        if (!driver) return <span className="text-red">Not assigned</span>;
        const { id, name: driverName } = driver;
        return (
          <CopyableText copyText={id} tooltipText={id}>
            <Link to={`/user/${id}`} className="anchor anchor-novisit">
              {driverName} - {truncate(id, 8, '')}
            </Link>
          </CopyableText>
        );
      },
    },
  ];

  let columns = [...defaultColumns];
  // if role is agent, we have different/some columns to add (@TODO don't check for role, but for permissions)
  if (role === 'AGENT') {
    columns.splice(2, 0, ...agentColumns);
    columns = columns.filter((column) => column.id !== 'alerts');
  }
  return columns;
};

type FormValues = {
  search: string;
  status$in: SHIPMENT_STATUS[];
  delayed: boolean | '';
  unarchived_alerts: boolean | '';
  created_at$from: string;
  created_at$to: string;
  collection_datetime$from: string;
  collection_datetime$to: string;
  delivery_datetime$from: string;
  delivery_datetime$to: string;
};

const ShipmentList = () => {
  const formRef = React.useRef<HTMLFormElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const [sorters, setSorters] = React.useState<SortingState>([{ id: 'created_at', desc: true }]);
  const [showMap, setShowMap] = React.useState<boolean>(window.localStorage.getItem('atlas:map') ? true : false);
  const [highlightedRow, setHighlightedRow] = React.useState<Shipment | null>(null);
  const [driverModal, setDriverModal] = React.useState<{ leg: Leg; shipment: Shipment } | null>(null);
  const me = useMe();

  const defaultValues: FormValues = {
    search: '',
    status$in: [],
    delayed: '',
    unarchived_alerts: '',
    created_at$from: '',
    created_at$to: '',
    collection_datetime$from: '',
    collection_datetime$to: '',
    delivery_datetime$from: '',
    delivery_datetime$to: '',
  };
  const defaultValuesWithSearchValues = { ...defaultValues, ...fromQuerystring(searchParams, ['status$in'], undefined, true) };
  const [values, setValues] = React.useState<typeof defaultValues>(defaultValuesWithSearchValues);

  const formContext = useForm({
    mode: 'onTouched',
    criteriaMode: 'all',
    shouldUnregister: true,
    shouldUseNativeValidation: false,
    shouldFocusError: true,
    defaultValues: defaultValuesWithSearchValues,
  });
  const { handleSubmit, formState, reset, setValue, getValues } = formContext;

  const { data: rowsCount } = useSWR(formState.isValid ? ['/shipment/count', values, emptyToUndefined] : null, POST);
  const swr = useSWRInfinite(getSWRKeyForPage('/shipment/list', 25, rowsCount, values, sorters, emptyToUndefined), POST);
  // we need to flatten the data because we are using useSWRInfinite which returns an array of pages with results
  const shipments: Shipment[] = React.useMemo(() => swr.data?.flat() || [], [swr]);

  const { data: stats } = useSWR<StatControllerListResponse>(['/stat/list'], GET);

  const onClickDriverAssign = React.useCallback((shipment: Shipment) => () => setDriverModal({ shipment, leg: shipment.legs[0] }), []);

  const columns = React.useMemo(() => getColumns(me?.role, shipments, showMap, onClickDriverAssign), [me?.role, shipments, showMap, onClickDriverAssign]);

  const toggleFilters = () => setShowFilters(!showFilters);

  const resetFilters = () => {
    reset(defaultValues);
    window.setTimeout(triggerSubmit, 50);
  };

  const setFilter = (name: keyof FormValues, value: any) => () => {
    resetFilters();
    setValue(name, value, { shouldDirty: true, shouldTouch: true });
    triggerSubmit();
  };

  // wait next tick or URL will not be updated
  const triggerSubmit = React.useCallback(() => window.setTimeout(() => formRef.current?.requestSubmit(), 50), []);

  const onSubmit = async (values: FormValues) => {
    setSearchParams(toQuerystring(values), { replace: true });
    setValues(values);
    await swr.mutate();
  };

  const onRowMouseEnter = (row: Shipment) => setHighlightedRow(row);
  const onRowMouseLeave = () => setHighlightedRow(null);

  const toggleMap = () => {
    window.localStorage.setItem('atlas:map', !showMap ? '1' : '');
    setShowMap(!showMap);
  };

  // we need active filters: if filter is of type array then we count if having least 1 selection, otherwise we count if being not nullish
  const filtersCount = Object.values(values).filter((value) => (Array.isArray(value) ? value.length > 0 : value !== '')).length;

  // todo: shipment may have packages not all together!
  const getShipmentPosition = React.useCallback((shipment: Shipment) => {
    const [pkg] = shipment.packages.filter((pkg: Package) => pkg.last_measured_at || pkg.tracker?.last_measured_at);
    const status = shipment.status;
    const activeLeg = getActiveLeg(shipment);
    if (!pkg || !activeLeg || !['transiting', 'pending'].includes(status)) {
      return null;
    }
    if (pkg.position) return stringToPosition(pkg.position);
    return stringToPosition(pkg.tracker?.position as any);
  }, []);

  const markers = React.useMemo(() => {
    if (!shipments.length) return [];

    const markers: Marker[] = [];

    const shipmentsWithPosition = shipments.filter(getShipmentPosition);
    for (const shipment of shipmentsWithPosition) {
      // @ts-ignore
      shipment.position = getShipmentPosition(shipment);
    }

    for (const shipment of shipmentsWithPosition) {
      if (me?.role === 'AGENT' && shipment.status !== 'transiting') continue;
      const shipmentIsHighlighted = highlightedRow && shipment.id === highlightedRow.id ? true : false;

      markers.push({
        // @ts-ignore
        position: [...shipment.position].reverse() as LatLngExpression,
        icon: MarkerTruck(cx(shipmentIsHighlighted && 'scale-150')),
        content: (
          <div className={'flex flex-col gap-2'}>
            Shipment <strong>{shipment.logistics_ex_id}</strong>
          </div>
        ),
      });
    }

    return markers;
  }, [shipments, highlightedRow, getShipmentPosition, me]);

  // we can't rely only on formState.isDirty because it's true when defaultValues provided to useForm are changed, but we may have filters set by default provided in the URL
  const clearFiltersEnabled = formState.isDirty || !isEqual(defaultValues, getValues());

  const onDriverAssignClose = () => setDriverModal(null);
  const onDriverAssignSave = () => {
    setDriverModal(null);
    void swr.mutate();
  };

  return (
    <main className="view-container">
      {driverModal && (
        <DriverAssignmentDialog
          key={driverModal.leg.id}
          shipment={driverModal.shipment}
          leg={driverModal.leg}
          isOpen={!!driverModal}
          onClose={onDriverAssignClose}
          onSave={onDriverAssignSave}
        />
      )}
      <section className="mb-10 flex h-16 flex-row gap-4">
        <div className="paper !p-3 flex grow cursor-pointer select-none flex-row items-center justify-start gap-5" onClick={setFilter('status$in', ['pending', 'transiting'])}>
          <Tag className="blue !cursor-pointer !border-0 !p-2 self-center">
            <ITruck size={20} />
          </Tag>
          <div className="relative flex flex-row items-end gap-2">
            <h3 className="leading-[100%]">{stats === undefined ? <Spinner size={24} /> : stats.TRANSITING_SHIPMENTS}</h3>
            <span>Active shipments</span>
          </div>
        </div>

        {me && me.role === 'ADMIN' && (
          <div className="paper !p-3 flex grow cursor-pointer select-none flex-row items-center justify-start gap-5" onClick={setFilter('unarchived_alerts', true)}>
            <Tag className="red !cursor-pointer !border-0 !p-2 self-center">
              <IWarning size={20} />
            </Tag>
            <div className="relative flex flex-row items-end gap-2">
              <h3 className="leading-[100%]">{stats === undefined ? <Spinner size={24} /> : stats.ALERTED_SHIPMENTS}</h3>
              <span>Shipments with alerts</span>
            </div>
          </div>
        )}
      </section>

      <section className="flex flex-row place-content-between align-baseline">
        <h4>Shipments</h4>

        {showMap && (
          <Button className="blue-outlined !px-2.5 h-max max-w-max py-1" LeftIcon={IMap} onClick={toggleMap}>
            Hide Map
          </Button>
        )}
        {!showMap && (
          <Button className="blue-outlined !px-2.5 h-max max-w-max py-1" LeftIcon={IMap} onClick={toggleMap}>
            Display Map
          </Button>
        )}
      </section>

      <div className={cx('grid gap-8', showMap ? 'grid-cols-2' : 'grid-cols-1')}>
        <section className="mt-4 rounded-2xl bg-white p-8 pb-4">
          <div>
            <FormProvider {...formContext}>
              <form id="search-form" ref={formRef} className="mb-8 flex flex-col gap-8" noValidate={true} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="flex place-content-between place-items-center">
                  <Input
                    className="w-[360px]"
                    name={'search'}
                    placeholder={'Search by Order Number'}
                    required={true}
                    autoFocus={true}
                    RightIcon={ISearch}
                    autoComplete={'off'}
                    debouncedAutoSubmitForm={'#search-form'}
                    debouncedAutoSubmitTimeout={500}
                  />

                  <IconBadge Icon={IFilters} className="cursor-pointer" iconClassname={'size-[24px] fill-gray-400'} badge={filtersCount || null} onClick={toggleFilters} />
                </div>

                {/* expanded filters section */}
                <div className={cx('flex flex-col gap-6 rounded-2xl bg-blue-sky p-4', !showFilters && 'hidden')}>
                  <div className="grid grid-cols-[repeat(auto-fill,200px)] gap-6">
                    <SelectMulti
                      label={'Status'}
                      name={'status$in'}
                      options={SHIPMENT_STATUSES as unknown as string[]}
                      selectable={'many'}
                      disableSearch={true}
                      closeOnChangedValue={true}
                      required={false}
                      hasClearAll={true}
                    />

                    <SelectMulti
                      label={'Delayed'}
                      name={'delayed'}
                      options={BooleanOptions}
                      selectable={'one'}
                      disableSearch={true}
                      closeOnChangedValue={true}
                      required={false}
                      hasClearAll={true}
                    />

                    <SelectMulti
                      className={cx(me?.role !== 'ADMIN' && 'hidden')}
                      label={'Alerts'}
                      name={'unarchived_alerts'}
                      options={
                        new Map([
                          [true, 'With alerts'],
                          [false, 'Without alerts'],
                        ])
                      }
                      selectable={'one'}
                      disableSearch={true}
                      closeOnChangedValue={true}
                      required={false}
                      hasClearAll={true}
                    />

                    <div className="col-span-2 col-start-1 grid grid-cols-subgrid">
                      <Input className="w-full [&>input]:bg-transparent" name="created_at$from" label="Creation From" type="datetime-local" registerOptions={{ required: false }} />
                      <Input className=" [&>input]:bg-transparent" name="created_at$to" label="Creation To" type="datetime-local" registerOptions={{ required: false }} />
                    </div>

                    <div className="col-span-2 grid grid-cols-subgrid">
                      <Input
                        className="col-start-1 [&>input]:bg-transparent"
                        name="collection_datetime$from"
                        label="Collection From"
                        type="datetime-local"
                        registerOptions={{ required: false }}
                      />
                      <Input
                        className=" [&>input]:bg-transparent"
                        name="collection_datetime$to"
                        label="Collection To"
                        type="datetime-local"
                        registerOptions={{ required: false }}
                      />
                    </div>

                    <div className="col-span-2 grid grid-cols-subgrid">
                      <Input className="[&>input]:bg-transparent" name="delivery_datetime$from" label="Delivery From" type="datetime-local" registerOptions={{ required: false }} />
                      <Input className="[&>input]:bg-transparent" name="delivery_datetime$to" label="Delivery To" type="datetime-local" registerOptions={{ required: false }} />
                    </div>
                  </div>

                  <div className="flex flex-row gap-4">
                    <Button className="blue-outlined h-max min-w-max max-w-max p-1" type="button" onClick={toggleFilters}>
                      Close
                    </Button>
                    <Button className="blue-outlined ml-auto min-w-max max-w-max" type="button" onClick={resetFilters} disabled={!clearFiltersEnabled}>
                      Clear filters
                    </Button>
                    <Button className="blue-outlined min-w-max max-w-max" disabled={showFilters && !formState.isDirty}>
                      Apply
                    </Button>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>

          <Table
            className={cx('max-h-[calc(100vh-420px)]', !shipments.length && 'min-h-[300px]')}
            columns={columns}
            swr={swr}
            pagination={true}
            rowsCount={rowsCount}
            defaultSorting={sorters}
            onColumnSort={setSorters}
            estimateSize={50}
            overscan={50}
            onRowMouseEnter={onRowMouseEnter}
            onRowMouseLeave={onRowMouseLeave}
            wrapHeaders={showMap}
          />
        </section>

        {showMap && (
          // 20px less than the table to prevent overflow
          <section className="relative mt-6">
            {(swr.isValidating || markers === undefined) && <Spinner size={48} centered={true} />}
            {!swr.isValidating && markers !== undefined && (
              <React.Suspense fallback={<Spinner centered={true} />}>
                <MapComponent defaultZoom={4} markers={markers} />
              </React.Suspense>
            )}
          </section>
        )}
      </div>
    </main>
  );
};

export default ShipmentList;
