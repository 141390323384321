import cx from 'clsx';
import { BaseError, errorCodes, errorMessages } from 'common/errors/index.ts';
import { GET, computeDistance, getActiveLeg, getAlertsToRead, getRuntimeTimezone, getStatusColor, statusToFill, stringToPosition, truncate, uniqueBy } from 'common/helpers.ts';
import { range } from 'es-toolkit';
import type { LatLngExpression } from 'leaflet';
import React from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';
import useSWR from 'swr';
import type { Leg as TLeg } from 'types/Leg.ts';
import type { Package } from 'types/Package.ts';
import type { Shipment as TShipment } from 'types/Shipment.ts';
import { Button } from 'ui/component/Button.tsx';
import { CopyableText } from 'ui/component/CopyableText.tsx';
import { ICamera, IClock, IEdit, IHistory, IInfo, IRight, IWarning } from 'ui/component/Icons.tsx';
import { Spinner } from 'ui/component/Spinner.tsx';
import { Stepper } from 'ui/component/Stepper.tsx';
import { Tag } from 'ui/component/Tag.tsx';
import { Tooltip } from 'ui/component/Tooltip.tsx';
import { Datetime } from '#admin/component/Datetime.tsx';
import { DocumentUploadDialog } from '#admin/component/DocumentUploadDialog.tsx';
import { DriverAssignmentDialog } from '#admin/component/DriverAssignmentDialog.tsx';
import { LegEditDialog } from '#admin/component/LegEditDialog.tsx';
import type { Marker, Polyline } from '#admin/component/Map.tsx';
import { PackageDetails } from '#admin/component/PackageDetails.tsx';
import { ShipmentHistory } from '#admin/component/ShipmentHistory.tsx';
import { TrackerAssignmentDialog } from '#admin/component/TrackerAssignmentDialog.tsx';
import { MarkerNumber, MarkerPackage } from '#admin/component/markers.tsx';
import useMe from '#admin/hook/useMe.tsx';
import { Leg, LegDetailItem } from '../component/Leg.tsx';

// This is the maximum distance in km that a package can be from the current leg's destination, otherwise it will be marked as red
const MAX_DISTANCE_M = 100;

// lazy load the map component
const MapComponent = React.lazy(() => import('../component/Map.tsx'));

const Shipment: React.FC = () => {
  const me = useMe();
  const { id: shipmentId } = useParams();
  const { data: shipment, mutate: mutateShipment } = useSWR<TShipment>([`/shipment/${shipmentId}`], GET, { keepPreviousData: true });
  const [uploadDialog, setUploadDialog] = React.useState<TLeg[] | null>(null);
  const [legEditDialog, setLegEditDialog] = React.useState<TLeg | null>(null);
  const [trackerDialog, setTrackerDialog] = React.useState<Package | null>(null);
  const [driverDialog, setDriverDialog] = React.useState<TLeg | null>(null);
  const [packageDialog, setPackageDialog] = React.useState<Package | null>(null);
  const [historyDialog, setHistoryDialog] = React.useState<boolean>(false);

  const geocodingIsMissing = React.useMemo(() => shipment?.legs.some((leg: TLeg) => !leg.origin_position || !leg.destination_position), [shipment]);

  const { alerts, alertsToRead } = React.useMemo(() => {
    if (!shipment) return { alerts: [], alertsToRead: [] };
    const alertsWithDupes = shipment.packages.flatMap((pkg: Package) => pkg.alerts);
    const alerts = alertsWithDupes.filter(uniqueBy('id'));
    const alertsToRead = getAlertsToRead(alerts);
    return { alerts, alertsToRead };
  }, [shipment]);

  const { firstLeg, lastLeg, currentLeg, steps, currentStep } = React.useMemo(() => {
    if (!shipment) return { firstLeg: undefined, lastLeg: undefined, currentLeg: undefined, steps: [], currentStep: undefined };

    // then we compute the firstLeg, lastLeg, currentLeg, steps, currentStep
    const firstLeg = shipment.legs[0];
    const lastLeg = shipment.legs[shipment.legs.length - 1];
    const currentLeg = getActiveLeg(shipment);
    const steps = range(1, shipment.legs.length + 2).map(String);
    // @ts-ignore => are we sure that currentLeg is not undefined? if they provide crappy statues, it could be: to verify
    const currentStep = shipment.legs.indexOf(currentLeg) + (currentLeg?.status === 'delivered' ? 2 : 1);
    return { firstLeg, lastLeg, currentLeg, steps, currentStep };
  }, [shipment]);

  // Calculate the furthest distance between any two trackers
  const furthestTrackerDistanceM = React.useMemo(() => {
    const trackers = shipment?.packages.map((pkg) => pkg.tracker).filter((tracker) => tracker?.position);
    if (!trackers || trackers.length < 2) return 0;
    let maxDistance = 0;
    for (let i = 0; i < trackers.length; i++) {
      const positionI = trackers[i]?.position;
      if (!positionI) continue;
      for (let j = i + 1; j < trackers.length; j++) {
        const positionJ = trackers[j]?.position;
        if (!positionJ) continue;
        const distance = computeDistance(stringToPosition(positionI), stringToPosition(positionJ));
        maxDistance = Math.max(maxDistance, distance);
      }
    }
    return maxDistance;
  }, [shipment]);

  const markers = React.useMemo(() => {
    if (!shipment || geocodingIsMissing || !lastLeg) return [];

    const markers: Marker[] = [];

    // push first leg origin
    const markerNumber = 1;
    const markerClassName = statusToFill(firstLeg.status);
    markers.push({
      // @ts-ignore
      position: [Number(firstLeg.origin_position[1]), Number(firstLeg.origin_position[0])],
      icon: MarkerNumber({ className: markerClassName, number: markerNumber }),
      content: <span>{firstLeg.origin_address}</span>,
    });

    // push legs destinations
    for (const leg of shipment.legs) {
      // this check is not needed, as we are already checking for geocodingIsMissing and it's only to silence typescript yelling about possible nulls
      if (!leg.origin_position || !leg.destination_position) {
        throw new BaseError(errorCodes.SHIPMENT_MISSING_GEOCODING, errorMessages.SHIPMENT_MISSING_GEOCODING);
      }

      const markerNumber = shipment.legs.indexOf(leg) + 2;
      const markerClassName = statusToFill(leg.status);
      markers.push({
        position: [Number(leg.destination_position[1]), Number(leg.destination_position[0])],
        icon: MarkerNumber({ className: markerClassName, number: markerNumber }),
        content: <span>{leg.destination_address}</span>,
      });
    }

    // If user is agent and current leg is not active, don't show the trackers
    if (me?.role === 'AGENT' && shipment.status !== 'transiting') {
      return markers;
    }

    // push package/s positions
    for (const pkg of shipment.packages) {
      const position = pkg.tracker?.position || pkg.position;

      // Filter out packages without a tracker or position (even though they are assigned, they might not have a position because MC job didn't run)
      if (!position) continue;

      const pkgId = pkg.logistics_ex_id || pkg.id;
      markers.push({
        position: stringToPosition(position).reverse() as LatLngExpression,
        icon: MarkerPackage(furthestTrackerDistanceM > MAX_DISTANCE_M ? 'bg-red' : undefined),
        content: (
          <div className="flex flex-col gap-2">
            <LegDetailItem label={'Package'}>
              <span className="cursor-pointer text-blue hover:underline" onClick={() => setPackageDialog(pkg)}>
                <CopyableText iconSize={14} copyText={pkgId} tooltipText={pkgId}>
                  #{truncate(pkgId, 8, '')}
                </CopyableText>
              </span>
            </LegDetailItem>
            {pkg.tracker && (
              <>
                <LegDetailItem label={'Tracker ID'}>
                  <Link to={`/tracker/${pkg.tracker.id}`} className="cursor-pointer text-blue hover:underline">
                    <CopyableText iconSize={14} copyText={pkgId} tooltipText={pkgId}>
                      #{truncate(pkg.tracker?.metrics_ex_id || pkg.tracker?.id, 8, '')}
                    </CopyableText>
                  </Link>
                </LegDetailItem>
                <LegDetailItem label={'Description'}>{pkg.tracker.description || ''}</LegDetailItem>
                <LegDetailItem label={'Battery'}>{(pkg.tracker.battery || 0) + '%'}</LegDetailItem>
                <LegDetailItem label={'Temperature'}>{pkg.tracker.temperature + '°C'}</LegDetailItem>
              </>
            )}
          </div>
        ),
      });
    }

    return markers;
  }, [shipment, geocodingIsMissing, firstLeg, lastLeg, furthestTrackerDistanceM, me]);

  const polylines = React.useMemo(() => {
    if (!shipment || geocodingIsMissing) return [];

    const polylines: Polyline[] = [];

    for (const leg of shipment.legs) {
      // this check is not needed, as we are already checking for geocodingIsMissing, and it's only to silence typescript yelling about possible nulls
      if (!leg.origin_position || !leg.destination_position) {
        throw new BaseError(errorCodes.SHIPMENT_MISSING_GEOCODING, errorMessages.SHIPMENT_MISSING_GEOCODING);
      }

      polylines.push({
        positions: [[...leg.origin_position].reverse(), [...leg.destination_position].reverse()] as LatLngExpression[],
        color: leg.status === 'transiting' ? 'aqua' : leg.status === 'delivered' ? 'blue' : 'grey',
      });
    }

    return polylines;
  }, [shipment, geocodingIsMissing]);

  const brokenAddresses: string[] = React.useMemo(() => {
    if (!shipment) return [];

    const brokenAddresses: Set<string> = shipment.legs.reduce<Set<string>>((accumulator, leg) => {
      if (!leg.origin_position) accumulator.add(leg.origin_address);
      if (!leg.destination_position) accumulator.add(leg.destination_address);
      return accumulator;
    }, new Set());

    const uniqueBrokenAddresses = Array.from(brokenAddresses);
    return uniqueBrokenAddresses;
  }, [shipment]);

  React.useEffect(() => {
    if (!shipment || !currentLeg) return;
    const legIndex = shipment.legs.indexOf(currentLeg) + 1;
    scrollToLeg(legIndex);
  }, [shipment, currentLeg]);

  const onStepClick = (step: string) => {
    const legIndex = Number(step) - 1;
    scrollToLeg(legIndex);
  };

  const scrollToLeg = (legIndex: string | number) => {
    const container = document.querySelector('#legs') as HTMLElement;
    const element = document.querySelector(`[data-leg="${String(legIndex)}"]`) as HTMLElement;
    if (!container || !element) return;
    const containerRect = container.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();
    container.scrollTop = elementRect.top - containerRect.top + container.scrollTop;
  };

  const onTrackerAssign = (pkg: Package) => () => setTrackerDialog(pkg);
  const onTrackerAssigned = async () => {
    setTrackerDialog(null);
    await mutateShipment();
  };

  const onDriverAssign = (leg: TLeg) => () => setDriverDialog(leg);
  const onDriverAssigned = async () => {
    setDriverDialog(null);
    await mutateShipment();
  };

  const onOpenHistoryDialog = () => setHistoryDialog(true);
  const onOpenLegEditDialog = (leg: TLeg) => () => setLegEditDialog(leg);
  const onCloseHistoryDialog = () => setHistoryDialog(false);

  const onClosePackageDialog = () => setPackageDialog(null);
  const onCloseDriverDialog = () => setDriverDialog(null);

  const onOpenUploadDialog = (legs: TLeg[]) => () => setUploadDialog(legs);
  const onCloseUploadDialog = async () => {
    setUploadDialog(null);
    await mutateShipment();
  };

  const onCloseLegEditDialog = async () => {
    setLegEditDialog(null);
    await mutateShipment();
  };

  const status = React.useMemo(() => {
    if (!shipment || !me) return undefined;
    return shipment.status;
  }, [me, shipment]);

  const shipmentStatusColor = React.useMemo(() => {
    if (!shipment || !status || !me) return 'grey';
    if (me.role === 'ADMIN') {
      return getStatusColor(status);
    }
    return getStatusColor(status);
  }, [shipment, status, me]);

  const originInfo: { name: string | null; address: string | null } = React.useMemo(() => {
    if (!me || !shipment) return { name: null, address: null };
    if (me.role === 'ADMIN') return { name: shipment.shipper.name, address: shipment.shipper.address };
    const firstLeg = shipment.legs[0];
    return { name: firstLeg.origin_address_name, address: firstLeg.origin_address };
  }, [me, shipment]);

  const destinationInfo: { name: string | null; address: string | null } = React.useMemo(() => {
    if (!me || !shipment) return { name: null, address: null };
    const lastLeg = shipment.legs[shipment.legs.length - 1];
    if (me.role === 'ADMIN') return { name: shipment.consignee.name, address: shipment.consignee.address };
    else return { name: lastLeg.destination_address_name, address: lastLeg.destination_address };
  }, [me, shipment]);

  if (!shipment || !firstLeg || !currentLeg || !lastLeg || !me) return <Spinner centered={true} />;

  return (
    <main className="view-container">
      {trackerDialog && (
        <TrackerAssignmentDialog
          key={trackerDialog.id}
          shipment={shipment}
          pkg={trackerDialog}
          isOpen={!!trackerDialog}
          onClose={() => setTrackerDialog(null)}
          onSave={onTrackerAssigned}
        />
      )}
      {driverDialog && (
        <DriverAssignmentDialog key={driverDialog.id} shipment={shipment} leg={driverDialog} isOpen={!!driverDialog} onClose={onCloseDriverDialog} onSave={onDriverAssigned} />
      )}
      {uploadDialog && (
        <DocumentUploadDialog
          key={uploadDialog[0].id || shipment.id}
          shipment={shipment}
          legs={uploadDialog}
          isOpen={!!uploadDialog}
          onClose={onCloseUploadDialog}
          onSave={onCloseUploadDialog}
        />
      )}

      {historyDialog && <ShipmentHistory shipment={shipment} isOpen={true} onClose={onCloseHistoryDialog} />}
      {packageDialog && <PackageDetails shipment={shipment} pkg={packageDialog} isOpen={!!packageDialog} onClose={onClosePackageDialog} />}
      {legEditDialog && (
        <LegEditDialog key={legEditDialog.id || shipment.id} leg={legEditDialog} isOpen={!!legEditDialog} onClose={onCloseLegEditDialog} onSave={onCloseLegEditDialog} />
      )}

      <section className="mb-6 flex flex-row items-center gap-6">
        <div className="flex flex-row gap-2">
          <span className="text-nowrap text-grey-label">Order number:</span>
          <strong className="w-max truncate">{shipment.logistics_ex_id}</strong>
        </div>
        <div className="flex flex-row gap-2">
          <span className="text-nowrap text-grey-label">Customer:</span>
          <strong className="w-max truncate">{shipment.customer.name}</strong>
        </div>

        <div className="ml-auto flex flex-row items-center gap-2">
          <span className="text-nowrap text-grey-label">Status:</span>
          <Tag className={cx('!p-2 capitalize', shipmentStatusColor)}>{status || '-'}</Tag>
          {shipment.delayed && (
            <Tooltip text={`This shipment ${shipment.status === 'delivered' ? 'was delivered' : 'is'} late.`}>
              <IClock className="fill-red" size={20} />
            </Tooltip>
          )}
        </div>
      </section>

      <div className="grid grid-cols-2 gap-6">
        <div className="flex flex-col gap-6">
          <section className="paper !p-6 flex flex-col gap-3">
            <h3 className="body2 pb-3">Shipment tracking</h3>
            <div className="place-content-between place-items-center">
              {!geocodingIsMissing && (
                <React.Suspense fallback={<Spinner centered={true} />}>
                  <MapComponent className="min-h-[300px] w-full" markers={markers} polylines={polylines} />
                </React.Suspense>
              )}
              {geocodingIsMissing && (
                <div className="flex min-h-[300px] w-full flex-col place-content-center place-items-center bg-gray-400 px-8 py-2 text-left text-white">
                  <IWarning className="mb-4 fill-yellow-500" size={60} />
                  <span>The following addresses could not be geocoded, tracker association and this map are disabled until they are fixed:</span>
                  <ul className="list-inside list-decimal">
                    {brokenAddresses.map((address, index) => (
                      <li key={index}>{address}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="body3 flex flex-row place-content-between gap-3">
              <span className="flex flex-row gap-2">
                <span className="text-grey-label">Pickup:</span>
                <span>
                  <strong className="block">{originInfo.name}</strong>
                  {originInfo.address}
                </span>
              </span>
              <span className="flex flex-row gap-2">
                <span className="text-grey-label">Delivery:</span>
                <span>
                  <strong className="block">{destinationInfo.name}</strong>
                  {destinationInfo.address}
                </span>
              </span>
            </div>

            <Stepper steps={steps} currentStep={String(currentStep)} onStepClick={onStepClick} currentLegStatus={currentLeg.status} />

            <div className="body3 flex flex-row place-content-between">
              <span className="flex flex-col items-center gap-2">
                <span className="text-grey-label">Expected pickup time</span>
                <Datetime timezone={firstLeg.origin_tz || getRuntimeTimezone()} truncateRegion={true}>
                  {firstLeg.origin_expected_datetime}
                </Datetime>
              </span>
              <span className="flex flex-col items-center gap-2">
                <span className="text-grey-label">Expected delivery time</span>
                <Datetime timezone={lastLeg.destination_tz || getRuntimeTimezone()} truncateRegion={true}>
                  {lastLeg.destination_expected_datetime}
                </Datetime>
              </span>
            </div>

            <hr />

            <div className="flex flex-row justify-start gap-3">
              <Button LeftIcon={ICamera} className="blue-outlined relative w-max gap-2" onClick={onOpenUploadDialog(shipment.legs)}>
                <div className="flex flex-row items-center gap-2">
                  <span>UPLOAD DOC.</span>
                  <Tooltip text="Upload document for all legs.">
                    <IInfo className="grey" size={16} />
                  </Tooltip>
                </div>
              </Button>
              <Button LeftIcon={IHistory} className="blue-outlined w-max" onClick={onOpenHistoryDialog}>
                HISTORY
              </Button>
              {shipment.metrics_ex_id && me?.role === 'ADMIN' && (
                <Link className="contents" to={new URL(`/shipment/${shipment.metrics_ex_id}/metricslink`, import.meta.env.VITE_API_URL).href} target="_blank" rel="noreferrer">
                  <Button className="blue-outlined max-w-max">View on Tec4Cloud</Button>
                </Link>
              )}
            </div>
          </section>

          <section className="paper !p-6">
            <h3 className="body2 pb-3">Packages</h3>
            <div
              className={cx(
                'mt-2 grid place-content-center place-items-center gap-3',
                me?.role === 'ADMIN' ? 'grid-cols-[1fr_2fr_50px_1.5fr_55px_30px]' : 'grid-cols-[1fr_2fr_30px]',
              )}
            >
              <span className="justify-self-start py-2 text-grey-label">Package</span>
              <span className="py-2 text-grey-label">Tracker</span>
              {me?.role === 'ADMIN' && <span className="py-2 text-grey-label">Temp</span>}
              {me?.role === 'ADMIN' && <span className="py-2 text-grey-label">Range</span>}
              {me?.role === 'ADMIN' && <span className="py-2 text-grey-label">Alerts</span>}
            </div>
            <hr />
            {shipment.packages.map((pkg: Package, index: number) => (
              <div
                key={index}
                className={cx(
                  'grid h-16 place-content-center place-items-center gap-3 border-b border-b-grey-border',
                  me?.role === 'ADMIN' ? 'grid-cols-[1fr_2fr_50px_1.5fr_55px_30px]' : 'grid-cols-[1fr_2fr_30px]',
                )}
              >
                <span className="max-w-full justify-self-start truncate py-3">
                  <span className="cursor-pointer text-blue hover:underline" onClick={() => setPackageDialog(pkg)}>
                    {truncate(pkg.logistics_ex_id, 8, '')}
                  </span>
                </span>
                <span className="max-w-full truncate py-3">
                  {shipment.status === 'delivered' && (
                    <>
                      {pkg.last_tracker && (
                        <div className="blue flex items-center justify-center gap-1">
                          <Tooltip text={`${pkg.last_tracker.metrics_ex_id} (${pkg.last_tracker?.description || ''})`}>
                            <NavLink to={`/tracker/${pkg.last_tracker.id}`} className="rounded-md p-1 hover:bg-gray-100">
                              <span className="uppercase">{pkg.last_tracker.description || pkg.last_tracker.metrics_ex_id}</span>
                            </NavLink>
                          </Tooltip>
                        </div>
                      )}
                      {!pkg.last_tracker && 'N/A'}
                    </>
                  )}
                  {shipment.status !== 'delivered' && (
                    <>
                      {!pkg.tracker && (
                        <>
                          {me?.role === 'ADMIN' && (
                            <Button onClick={onTrackerAssign(pkg)} className="blue-outlined !min-w-[100px] !max-w-full text-nowrap normal-case" disabled={geocodingIsMissing}>
                              ADD TRACKER
                            </Button>
                          )}
                          {me?.role !== 'ADMIN' && 'N/A'}
                        </>
                      )}
                      {pkg.tracker && (
                        <div className="blue flex items-center justify-center gap-1">
                          <Tooltip text={`${pkg.tracker.metrics_ex_id} (${pkg.tracker?.description || ''})`}>
                            <NavLink to={`/tracker/${pkg.tracker.id}`} className="rounded-md p-1 hover:bg-gray-100">
                              <span className="uppercase">{pkg.tracker.description || pkg.tracker.metrics_ex_id}</span>
                            </NavLink>
                          </Tooltip>
                          {me?.role === 'ADMIN' && (
                            <span onClick={onTrackerAssign(pkg)} className="rounded-md p-1 hover:cursor-pointer hover:bg-gray-100">
                              <Tooltip text="Reassign Tracker">
                                <IEdit size={17} />
                              </Tooltip>
                            </span>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </span>
                {me?.role === 'ADMIN' && <span className="max-w-full truncate py-3">{pkg.temperature ? pkg.temperature + 'ºC' : '-'}</span>}
                {me?.role === 'ADMIN' && (
                  <span className="max-w-full truncate py-3">
                    {pkg.th_max_temperature && pkg.th_min_temperature ? `${pkg.th_min_temperature}ºC / ${pkg.th_max_temperature}ºC` : '-'}
                  </span>
                )}
                {me?.role === 'ADMIN' && (
                  <span className="py-3">
                    {!alerts.length && '0/0'}
                    {!!alerts.length && (
                      <Tag
                        className={cx('tag-alerts cursor-pointer', pkg.alerts.filter((alert) => !alert.archived_at).length > 0 ? 'red' : 'grey')}
                        onClick={() => setPackageDialog(pkg)}
                      >
                        {alertsToRead.length} of {alerts.length}
                      </Tag>
                    )}
                  </span>
                )}
                {pkg.tracker && (
                  <span className="flex items-center justify-center p-3">
                    <NavLink to={`/tracker/${pkg.tracker.id}`}>
                      <IRight size={28} className="text-grey-label text-lg" />
                    </NavLink>
                  </span>
                )}
                {!pkg.tracker && <span />}
              </div>
            ))}
          </section>
        </div>

        <div className="flex flex-col rounded-xl border border-slate-200 bg-white">
          <h3 className="body2 p-6">Shipment details</h3>

          <section id="legs" className="flex grow basis-0 scroll-p-6 flex-col gap-6 overflow-auto scroll-smooth px-8 pb-8">
            {/* snap-mandatory*/}
            {shipment.legs.map((leg, index) => (
              <Leg
                key={index}
                index={index}
                leg={leg}
                role={me.role}
                onDriverAssign={onDriverAssign}
                onOpenUploadDialog={onOpenUploadDialog}
                onOpenLegEditDialog={onOpenLegEditDialog}
              />
            ))}
          </section>
        </div>
      </div>
    </main>
  );
};

export default Shipment;
