import React from 'react';
import { Button } from 'ui/component/Button.tsx';
import { IDelete, IPhotoScan } from 'ui/component/Icons.tsx';

const fileTypeMatchesAccept = (type: string, accept: string) => {
  const acceptRegex = new RegExp(accept.replace(/\*/g, '.*').replace(/\,/g, '|'));
  return acceptRegex.test(type);
};

type Props = {
  accept?: string;
  onChange: (file: File | null) => void;
  value: File | null;
  error?: { message?: string };
};

export const FileUpload: React.FC<Props> = ({ accept, onChange, value, error }) => {
  const fileInputId = React.useId();

  const onDragOver = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
  };

  const handleChange = (file: File) => {
    if (accept && !fileTypeMatchesAccept(file.type, accept)) {
      onChange(null);
      return;
    }
    onChange(file);
  };

  return (
    <div className="w-full">
      <input
        type="file"
        id={fileInputId}
        className="hidden"
        accept={accept}
        value=""
        onChange={(event) => {
          const {
            target: { files },
          } = event;
          const [file] = files || [];
          handleChange(file);
        }}
      />
      {value ? (
        <div className="flex flex-row items-center gap-3.5 rounded-2xl border border-gray-200 px-6 py-4">
          <IPhotoScan size={20} className="shrink grow-0 text-grey-label" />
          <span className="body3 shrink-0 grow text-grey-body">{value?.name}</span>
          <Button
            className="!h-fit !w-fit !p-0 border-none text-blue"
            onClick={() => {
              onChange(null);
            }}
          >
            <IDelete size={20} />
          </Button>
        </div>
      ) : (
        <div
          className="flex h-[12.5rem] w-full flex-col items-center justify-center gap-2 rounded-lg border-2 border-grey-border border-dashed"
          onDrop={(event) => {
            event.preventDefault();
            const [file] = event.dataTransfer.files;
            handleChange(file);
          }}
          onDragOver={onDragOver}
        >
          <div className="flex flex-row items-center gap-3">
            <span className="body1 shrink-0 grow text-grey-label">Drop a file here or</span>
            <label htmlFor={fileInputId} className="button blue-outlined w-fit cursor-pointer rounded-3xl p-3 px-5 uppercase">
              Choose File
            </label>
          </div>
          <span className="text-red">{error?.message}</span>
        </div>
      )}
    </div>
  );
};
